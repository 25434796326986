<template>
  <div class="filtered-properties">
    <div class="list" v-if="properties.length > 0">
      <div v-for="p in properties" :key="p.token">
        <span class="title">{{ p.translations[0].title }}</span>
        <button @click="$emit('removeFavorite', p)" v-if="isAlreadyAdded(p)">{{ $t("overall.remove") }}</button>
        <button @click="$emit('addFavorite', p)" v-else>{{ $t("clients.add_favorite") }}</button>
      </div>
    </div>
    <span v-else>{{ $t("clients.no_matching_property") }}</span>
    <footer class="flex">
      <button class="colored" @click="$emit('previousTab')">{{ $t("overall.back") }}</button>
      <div class="cta" v-on:click="$emit('changeTab')">{{ $t("clients.selected_properties") }}</div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "DealFilteredProperties",
  props: ["filters", "favorite"],
  data() {
    return {
      properties: [],
    };
  },
  computed: {},
  methods: {
    isAlreadyAdded(el) {
      return this.favorite.find((fav) => fav.id === el.id) !== undefined;
    },
  },

  created() {
    let url = "";
    for (let prop in this.filters) {
      if (this.filters[prop] !== null) {
        if (prop === "price" || prop === "bedrooms" || prop === "bathrooms" || prop === "indoor_area") {
          url += prop + "[g]=" + this.filters[prop].from + "&";
          url += prop + "[l]=" + this.filters[prop].to + "&";
        } else if (prop === "type" || prop === "status") {
          url += prop + "[]=" + this.filters[prop] + "&";
        } else {
          url += prop + "=" + this.filters[prop] + "&";
        }
      }
    }
    url = url.substr(0, url.length - 1);
    this.$axios.get("/api/properties/search?" + url).then((res) => {
      console.log(res.data);
      this.properties = res.data;
    });
  },
});
</script>
